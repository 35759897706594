import React, { useState, useEffect } from 'react';
import '../global.css';
import Slider from '../components/Slider';

const Research = () => {
  const [researchItemsData, setResearchItemsData] = useState({ features: [], heading: '' });

  useEffect(() => {
    const fetchResearchItemsData = async () => {
      try {
        const response = await fetch('/landingPageData.json');
        if (!response.ok) {
          throw new Error('Failed to fetch research items data');
        }
        const data = await response.json();
        setResearchItemsData(data.researchItems); 
      } catch (error) {
        console.error('Error fetching research items data:', error);
      }
    };

    fetchResearchItemsData();
  }, []);

  return (
    <Slider features={researchItemsData.features} fontColor={localStorage.getItem("heroFontColor")} heading={researchItemsData.heading} />
  );
};

export default Research;
