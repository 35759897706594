import React, { useState, useEffect } from 'react';
import Slider from './Slider'; 
import '../global.css';

const Blogs = () => {
  const [blogData, setBlogsData] = useState({ features: [], blogUrls: [], heading: '' });


  useEffect(() => {
    const fetchBlogsData = async () => {
      try {
        const response = await fetch('/landingPageData.json');
        if (!response.ok) {
          throw new Error('Failed to fetch scroll items data');
        }
        const data = await response.json();
        setBlogsData(data.Blogs); 
      } catch (error) {
        console.error('Error fetching scroll items data:', error);
      }
    };

    fetchBlogsData();
  }, []);

  return (
    <Slider  features={blogData.features} fontColor={localStorage.getItem("heroFontColor")} heading={blogData.heading} />
  );
};

export default Blogs;
