import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you are using React Router
import axios from 'axios';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Roles = () => {
    const { role } = useParams();
    const [rolesData, setRolesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jobData, setJobData] = useState([]);
    const [sections, setSections] = useState([]);
    const [wideImage, setWideImage] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [jobLabel, setJobLabel] = useState('');
    const [sectionHeading, setSectionHeading] = useState('');
    const [bgColor, setBgColor] = useState("");
    const [fontColor, setFontColor] = useState("");
    const [message, setMessage] = useState(''); // State to handle messages

    const subDomain = localStorage.getItem('subDomain');
    let schoolName = subDomain;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getEducatorsDetails', {
                    params: { school: schoolName },
                });
                
                if (!response.data.success) {
                    setMessage(response.data.message); // Set message from the response
                    setLoading(false);
                    return;
                }

                const filteredData = response.data.roles.filter(sectionDetails => {
                    const roleName = role.split('=')[1];
                    return roleName === sectionDetails.title;
                });

                const firstItem = filteredData[0];
                setRolesData(firstItem);
                setSections(firstItem.sections);
                setJobData(firstItem.jobData);
                setSectionHeading(firstItem.title);
                setJobLabel(firstItem.label);
                setHeaderText(firstItem.headerText);
                setWideImage(firstItem.wideImage);
                setBgColor(firstItem.backgroundColor);
                setFontColor(firstItem.fontColor);

            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [role]);

    function isEnglish(text) {
        const pattern = /^[\u0000-\u007F\u00A0-\u00FF\u2018\u2019\u201C\u201D\s.,!?'"-]+$/; 
        return pattern.test(text);
      }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (message) {
        return <div>{message}</div>; // Render the message from the response
    }

    if (!rolesData || !jobData || !sections || !headerText || !sectionHeading) {
        return <div>Data not found!</div>;
    }

    return (
        <div style={{ backgroundColor: bgColor || "black", color: fontColor || "#ffe6c8" }}>
            <div style={{ height: '100%', paddingTop: '100px' }}>
                <div className="gap-3 gap-md-5">
                    <div className="vp-container container-fluid">
                        <section>
                        <h4
                            className={`mb-3 section-margin common-textshadow ${
                                !isEnglish(sectionHeading) ? "otherlang_custom-large-heading" : "custom-large-heading"
                            }`}
                        >
                            {sectionHeading}
                        </h4>
                        </section>
                        <section className='vp-section pb-5'>
                            <div className='justify-content-center'>
                                <div className='d-flex flex-column align-items-start text-left'>
                                    <p className={`common-textshadow mb-3 vp-container lh-lg text-center ${
                                            !isEnglish(headerText) ? "otherLangFontSize2_5rem" : "montser-mdm-1-5rem"
                                        }`}
                                    >
                                        {headerText}
                                    </p>

                                    <div className="width-100">
                                        <img src={wideImage} alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="vp-section">
                        <div className="section-container">
                            <div className="row justify-content-center align-items-center">
                            <div className="col-md-6 col-12 d-flex flex-column align-items-center"> {/* Center the column */}
                                {jobData.map((job, index) => (
                                <div key={index} className="mb-5 job-card">
                                    <div className="d-flex justify-content-between align-items-center job-container">
                                    <div className="d-flex flex-column text-center w-100"> {/* Center text and make full-width */}
                                        <h5 className="common-textshadow montser-mdm-1-5rem ft-4vh mb-0">
                                        {jobLabel}
                                        </h5>
                                        <div className="mb-3">
                                        <span
                                            className={` common-textshadow ${
                                                !isEnglish(job.title) ? "otherLangFontSize2_5rem" : "montser-mdm-1-5rem"
                                            }`}
                                            >
                                            {job.title}
                                        </span>

                                        </div>
                                         <div className="mb-3">
                                         <p className={`montser-sml-LightItalic common-textshadow mb-0 text-left ${
                                            !isEnglish(job.title) ? "otherLangFontSize1_8rem" : "ft-2-3vh"
                                            }`}>
                                                {job.description.split('\n').map((line, index) => (
                                                <span key={index}>
                                                    {line}
                                                    {index < job.description.split('\n').length - 1 && <br />}
                                                </span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="icon-container">
                                        <i className="demo-icon icon-checkiconsolid" />
                                    </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            </div>
                        </div>
                        </section>
                        <div className="vp-container container-fluid">
                            <div className='section-container flex-column'>
                                {sections.map((section, index) => {
                                    const isEven = index % 2 === 1;
                                    const isLastSection = index === sections.length - 1;
                                    return (
                                        <section key={index} className='vp-section'>
                                            <div
                        className={`row align-items-center ${
                          isLastSection ? "pb-5" : ""
                        }`}
                      >
                        <div
                          className={`col-sm-5 col-12 ms-3 ${
                            isEven ? "order-md-last" : ""
                          } d-flex justify-content-center`}
                        >
                          <div
                            className="w-100 maxw-450 d-flex mt-5 me-5"
                            style={{ marginLeft: "5.5vw" }}
                          >
                                                        <img src={section.imgSrc} alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img" />
                                                    </div>
                                                </div>
                                                <div className={`col-sm-6 col-12 ${isEven ? "order-md-first" : ""} mt-5 mt-sm-0`}>
                                                <div className="inner-container gap-5 d-flex flex-column align-items-start" style={{ marginLeft: "3vw" }}>
                                                        <div>
                                                        <p className={`text-start common-textshadow ${
                                                            !isEnglish(section.text) ? "otherLangFontSize1_8rem" : "montser-mdm-1-2rem"
                                                            }`}
                                                        >
                                                        {section.text}
                                                        </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
