// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* .researchSectionActive   */

  .bg-body-research{
    background-color: var(--bs-bg-body) !important;
    color: var(--bs-text-body) !important;
  }

  .researchSectionActive .bg-dark {
    background: #004c4c !important;
    color: #1c2933 !important;
  }
  
  .researchSectionActive .text-yellow {
    color: #e2eecc  !important;
  } 
  
  .researchSectionActive .bg-dark-footer{
    background: transparent !important;
    color: #e2eecc  !important;
  }`, "",{"version":3,"sources":["webpack://./src/research/research.css"],"names":[],"mappings":";EACE,6BAA6B;;EAE7B;IACE,8CAA8C;IAC9C,qCAAqC;EACvC;;EAEA;IACE,8BAA8B;IAC9B,yBAAyB;EAC3B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kCAAkC;IAClC,0BAA0B;EAC5B","sourcesContent":["\n  /* .researchSectionActive   */\n\n  .bg-body-research{\n    background-color: var(--bs-bg-body) !important;\n    color: var(--bs-text-body) !important;\n  }\n\n  .researchSectionActive .bg-dark {\n    background: #004c4c !important;\n    color: #1c2933 !important;\n  }\n  \n  .researchSectionActive .text-yellow {\n    color: #e2eecc  !important;\n  } \n  \n  .researchSectionActive .bg-dark-footer{\n    background: transparent !important;\n    color: #e2eecc  !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
