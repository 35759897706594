import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './research.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ResearchDashboard = ({ dataPath }) => {
  const [researchData, setResearchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming dataPath is correctly passed and points to your JSON file
        const response = await fetch(dataPath);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setResearchData(data.researchData); // Assuming "researchData" is the key in your JSON structure
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      }
    };

    fetchData();
  }, [dataPath]);

  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("researchSectionActive");
  }, []);

  return (
    <div>
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-research'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin common-textshadow'>Research</h4>

          </section>

          <div className="row text-yellow pt-2 gx-5 img-spacing">
            {researchData.map((item, index) => (
              <div className="col-md-6 col-lg-4 my-4" key={index}>
                <div className="card border-0 text-yellow bg-transparent">
                  <a href={item.url} className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                    <img src={item.imageSrc} alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
                    <div className='lh-lg mt-3'>
                      <span className="scroll-item mb-1 text-yellow common-textshadow montser-sml-LightItalic">{item.author}</span><br />
                      <span className="scroll-item mb-1 text-yellow .montser-lrg-bold-0-8rem">{item.title}</span><br />
                      <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic-1rem">{item.journal}</span>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default ResearchDashboard;
