import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import "@fontsource/montserrat";
import axios from 'axios';
import AppNavigations from './navigations/AppNavigations';

const App = ({ hostname }) => {
  const [favicon, setFavicon] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const schoolName = localStorage.getItem('subDomain');

      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });

        if (response.data.success === false) {
          console.error(response.data.message);
        } else {
          const logoUrl = response.data.logo.logo;
          console.log('Fetched logo URL:', logoUrl);
          document.title = response.data.brand.name;
          if(schoolName === "virtupreplabs"){
            document.title = "Virtu Prep"
          }
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl, response.data.fontColor);  // Pass fontColor here
          } else {
            setFavicon(logoUrl);  // For PNG/JPEG, set the image URL directly
          }
        }
      } catch (err) {
        console.error("Error loading data:", err);
      }
    };

    fetchData();
  }, []);

  const fetchAndModifySVG = async (svgUrl, fontColor) => {
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');

      if (svgElement) {
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', fontColor);   // Use the passed fontColor
          path.setAttribute('stroke', fontColor); 
        });

        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);

        // Convert SVG string to a data URL for use as a favicon
        const dataUrl = 'data:image/svg+xml;base64,' + btoa(modifiedSvgText);
        setFavicon(dataUrl); // Set the data URL as the favicon
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  // Update favicon whenever it changes
  useEffect(() => {
    if (favicon) {
      // Remove any existing favicon link elements
      const existingFavicon = document.querySelector("link[rel*='icon']");
      if (existingFavicon) {
        existingFavicon.remove();
      }

      // Create a new favicon link element
      const link = document.createElement('link');
      link.type = 'image/png';
      link.rel = 'icon';
      link.href = favicon;

      // Append the new favicon to the document head
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [favicon]);

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); 
    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  };

  return (
    <BrowserRouter>
      <AppNavigations />
    </BrowserRouter>
  );
};

export default App;
